import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<footer class=\"footer\">\n  <nav class=\"footer__navigation\">\n    <ul class=\"footer__navigation-list\">\n      <li>\n        <a\n          href=\"https://pix.fr/mentions-legales/\"\n          target=\"_blank\"\n          class=\"footer-navigation__item\"\n          rel=\"noopener noreferrer\"\n        >\n          {{t \"navigation.footer.legal-notice\"}}\n        </a>\n      </li>\n\n      <li>\n        <a\n          href=\"https://pix.fr/accessibilite-pix-certif/\"\n          target=\"_blank\"\n          class=\"footer-navigation__item\"\n          rel=\"noopener noreferrer\"\n        >\n          {{t \"navigation.footer.a11y\"}}\n        </a>\n      </li>\n    </ul>\n  </nav>\n\n  <div class=\"footer__copyright\">\n    <span>{{this.currentYear}}</span>\n  </div>\n</footer>", {"contents":"<footer class=\"footer\">\n  <nav class=\"footer__navigation\">\n    <ul class=\"footer__navigation-list\">\n      <li>\n        <a\n          href=\"https://pix.fr/mentions-legales/\"\n          target=\"_blank\"\n          class=\"footer-navigation__item\"\n          rel=\"noopener noreferrer\"\n        >\n          {{t \"navigation.footer.legal-notice\"}}\n        </a>\n      </li>\n\n      <li>\n        <a\n          href=\"https://pix.fr/accessibilite-pix-certif/\"\n          target=\"_blank\"\n          class=\"footer-navigation__item\"\n          rel=\"noopener noreferrer\"\n        >\n          {{t \"navigation.footer.a11y\"}}\n        </a>\n      </li>\n    </ul>\n  </nav>\n\n  <div class=\"footer__copyright\">\n    <span>{{this.currentYear}}</span>\n  </div>\n</footer>","moduleName":"pix-certif/components/layout/footer.hbs","parseOptions":{"srcName":"pix-certif/components/layout/footer.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Footer extends Component {
  @service intl;

  get currentYear() {
    const currentYear = new Date().getFullYear();
    return this.intl.t('navigation.footer.current-year', { currentYear });
  }
}
